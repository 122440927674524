<template>
    <div class="container" v-if="isAdmin">
      <div class="navigation_panel">
        <button class="btn btn-light" v-on:click="goVacation()">
          Відпустки
        </button>
        <button class="btn btn-light" v-on:click="goNotPayedOtgul()">
          Відгули за свій рахунок
        </button>
        <button class="btn btn-light" v-on:click="goOtgul()">
          Відгули за рахунок компанії
        </button>
      </div>
      <b-card v-if="currentSection === 1" title="Відпустки">
        <div class="large-12 medium-12 small-12 cell" >
          <div class="row" v-if="isAdmin">
            <div class="col-sm-10">
              <input type="file" class="btn btn-light" id="file" ref="file" accept=".xlsx,.xls" v-on:change="handleFileUpload()"/>
            </div>
            <div class="col-sm-2">
              <button class="btn btn-primary" v-on:click="save">Зберегти данні</button>
            </div>
          </div>
            <b-table class="table table-sm table-striped table-bordered"
                id="my-table"
                :items="vacations"
                :fields="fields"
                :sort-by="sortBy"
                :per-page="perPage"
                :current-page="currentPage"
                small
            ></b-table>
            <b-pagination align="center"
                v-model="currentPage"
                :total-rows="vacationsTotal"
                :per-page="perPage"
                aria-controls="my-table"
            ></b-pagination>
          </div>
      </b-card>
      <b-card v-if="currentSection === 2" title="Відгули за рахунок компанії">
        <div class="large-12 medium-12 small-12 cell" >
          <div class="row" v-if="isAdmin">
            <div class="col-sm-10">
              <input type="file" class="btn btn-light" id="file" ref="file" accept=".xlsx,.xls" v-on:change="handleFileUpload()"/>
            </div>
            <div class="col-sm-2">
              <button class="btn btn-primary" v-on:click="saveOtgul()">Зберегти данні</button>
            </div>
          </div>
            <b-table class="table table-sm table-striped table-bordered"
                id="my-table"
                :items="jsonForOtgulTables"
                :fields="fields"
                :sort-by="sortBy"
                small
            ></b-table>
            <b-pagination align="center"
                v-model="currentPage"
                :total-rows="jsonFileForOtguls.length"
                :per-page="40"
                aria-controls="my-table"
            ></b-pagination>
          </div>
      </b-card>
      <b-card v-if="currentSection === 3" title="Відгули за свій рахунок">
        <div class="large-12 medium-12 small-12 cell" >
          <div class="row" v-if="isAdmin">
            <div class="col-sm-10">
              <input type="file" class="btn btn-light" id="file" ref="file" accept=".xlsx,.xls" v-on:change="handleFileUpload()"/>
            </div>
            <div class="col-sm-2">
              <button class="btn btn-primary" v-on:click="saveOtgulNotPayed()">Зберегти данні</button>
            </div>
          </div>
            <b-table class="table table-sm table-striped table-bordered"
                id="my-table"
                :items="jsonForOtgulTables"
                :fields="fields"
                :sort-by="sortBy"
                :per-page="perPage"
                :current-page="currentPage"
                small
            ></b-table>
            <b-pagination align="center"
                v-model="currentPage"
                :total-rows="jsonFileForOtguls.length"
                :per-page="perPage"
                aria-controls="my-table"
            ></b-pagination>
          </div>
      </b-card>
    </div>
</template>

<script>
  //import PageTitle from "../../Layout/Components/PageTitle.vue";
  import XLSX from 'xlsx/dist/xlsx.full.min.js';
  import { mapGetters } from "vuex";

  export default {
    components: {  },

    data(){
      return {
          jsonFile: [],
          jsonFileForOtguls:[],
          jsonForOtgulTables:[],
          currentSection: 1,
          perPage: 20,
          currentPage: 1,
          sortBy: 'name',
          fields: [{ key: 'no', sortable: false },
                  { key: 'name', sortable: false },
                  { key: 'email', sortable: false },
                  { key: 'startDate', sortable: false },
                  { key: 'endDate', sortable: false },
                  { key: 'daysTotal', sortable: false },
                  { key: 'daysUsed', sortable: false },
                  { key: 'daysLeft', sortable: false },
                ],
          //vacations: this.$store.state.vacations.vacations
      }
    },
    computed: {
      ...mapGetters({ vacations: "vacations" ,
                      isAdmin: "isAdmin",
                      vacationsTotal: "vacationsTotal",
      }),
    },
    methods: {
      goOtgul(){
        this.currentSection = 2;
      },
      goNotPayedOtgul(){
        this.currentSection = 3;
      },
      goVacation(){
        this.currentSection = 1;
      },
      changeFields(item){
        item.daysLeft = item.DaysLeft;
        item.daysTotal = item.DaysTotal;
        item.email = item.Email;
        item.endDate = item.EndDate;
        item.name = item.Name;
        item.startDate = item.StartDate;
        // item.DaysLeft = null;
        // item.DaysTotal = null;
        // item.Email = null;
        // item.EndDate = null;
        // item.Name = null;
        // item.StartDate = null;
        return item;
      },
      async handleFileUpload(){
        const excelFile = this.$refs.file.files[0];

        var reader = new FileReader();
        let result = [];
        var that = this;
        reader.onload = function (e) {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: 'binary' });
          workbook.SheetNames.forEach((sheetName) => {
             result.push({
                sheetName: sheetName,
                sheet: XLSX.utils. sheet_to_json(workbook.Sheets[sheetName])
             })
          });
            console.log(result[0].sheet);
            that.jsonFileForOtguls = result[0].sheet;
            for(var j = 0; j< that.jsonFileForOtguls.length; j++){
              that.jsonForOtgulTables.push(that.changeFields(that.jsonFileForOtguls[j]));
            }
        }
        reader.readAsBinaryString(excelFile);

        this.jsonFile = result;
        //   console.log(this.jsonFile.shift());
        //   console.log(result.__ob__.value);

      },
      save() {
        if(this.jsonFile.length > 0) {
          const data = {SheetName: this.jsonFile[0].sheetName, Sheet: this.jsonFile[0].sheet}
          this.$store.dispatch("saveVacations", data)
          .then(() => {this.$store.dispatch("getVacations")});
        } else {
          alert("It takes time to download the file. Wait please")
        }

      },
      async saveOtgul() {
        if(this.jsonFileForOtguls.length > 0) {
          var datalist = [];
          for(var i = 0; i < this.jsonFileForOtguls.length; i++){
            var item = this.jsonFileForOtguls[i];
            item.Id = item.Num;
            item.Num=null;
            datalist.push(item);
          }
          console.log(datalist);
          var res = await fetch('https://vacation-inch-backend.azurewebsites.net/api/otgul/postpayedotguls', {
            method:'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(datalist) 
          })
          if(res.status !== 204){
            console.log('smth goes wrong')
            console.log(res);
          }
          else{
            // for(var j = 0; j< this.jsonFileForOtguls.length; j++){
            //   this.jsonForOtgulTables.push(this.changeFields(this.jsonFileForOtguls[j]));
            // }
            alert('Збережено');
          }
        } else {
          alert("It takes time to download the file. Wait please")
        }

      },
      async saveOtgulNotPayed(){
        if(this.jsonFileForOtguls.length > 0) {
          var datalist = [];
          for(var i = 0; i < this.jsonFileForOtguls.length; i++){
            var item = this.jsonFileForOtguls[i];
            item.Id = item.Num;
            item.Num=null;
            datalist.push(item);
          }
          console.log(datalist);
          var res = await fetch('https://vacation-inch-backend.azurewebsites.net/api/otgul/PostNotPayedOtguls', {
            method:'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(datalist) 
          })
          if(res.status !== 204){
            console.log('smth goes wrong')
          }
          else{
            // for(var j = 0; j< this.jsonFileForOtguls.length; j++){
            //   this.jsonForOtgulTables.push(this.changeFields(this.jsonFileForOtguls[j]));
            // }

            alert('Збережено');
          }
        } else {
          alert("It takes time to download the file. Wait please")
        }
      },
      logout: function () {
        this.$store.dispatch('logout');
      }
    },
    //created() {
    //  this.$store.dispatch("getVacations");
    //} 
  }
</script>
<style scoped lang="scss">
.navigation_panel{
  display:flex;
  justify-content: space-between;
  padding-bottom: 20px;
}
</style>
